body,
html,
.overflowx-hidden {
  overflow-x: hidden !important;
}

body,
html {
  padding-right: 0px !important;
}

.error-msg {
  color: red !important;
}

.primaryBacgrounf {
  background-color: #297ABD;
}

.skillset input {
  cursor: pointer;
}

.secondaryBackground {
  background-color: #F79433;
}

.secondaryColor {
  color: #F79433;
}

.dotsAdd {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  cursor: pointer;
}

.rangeslider__handle-tooltip {
  display: none;
}

.content-wrapper {
  margin-top: 8.5rem;
}

.bottom-10 {
  bottom: 10px;
}

.min-height-235 {
  min-height: 200px;
}

.colorWhite {
  color: #fff !important;
}

.fontSize25 {
  font-size: 2.5rem;
}

.z-index {
  z-index: 99;
}

.cursorPointer {
  cursor: pointer;
}

.fontSizex-large {
  font-size: x-large;
}

.more-less {
  color: #7B7575;
  font-size: 0.8rem;
  cursor: pointer;
}

.main {
  z-index: 999;
}

.transition5 {
  transition: transform 0.4s ease;
  ;
}

.transform80 {
  transform: translate(80%, 0);
}


.top0 {
  top: 0 !important;
}

* {
  font-family: 'Roboto';
}

label {
  margin-bottom: 0px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.active {
  color: #297ABD !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ul,
li {
  list-style: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* _________________________ App __________________ */
header {
  top: 0;
}

.rtm-menu>li {
  max-width: 250px;
}

#nav ul.rtm-menu a,
#nav .rtm-menu ul a {
  color: #000000;
}

#nav .rtm-menu li a {
  font-size: 14px;
}

ul.rtm-menu .main-title-with-desc,
.rtm-menu ul .main-title-with-desc,
#rnmm .main-title-with-desc,
#rnmm ul .main-title-with-desc {
  display: inline-block;
  line-height: 3;
}

.color_F79433 {
  color: #F79433 !important;
}

.search {
  width: 130px;
  height: 40px;
  background: #F79433;
  border-radius: 30px;

  line-height: 150%;
  text-align: center;
  border: 1px solid #F79433;
  cursor: pointer;

}

.searchDisabled {
  width: 130px;
  height: 40px;
  background: gray;
  border-radius: 30px;
  color: white;
  line-height: 150%;
  text-align: center;
  border: 1px solid gray;
  cursor: not-allowed;
}

.search {
  font-size: 14px;
  text-decoration: none;
}

.right-side-content {
  display: inline-block;
  height: 100%;
}

.right-side-content>img {
  width: 100%;
  height: 100%;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;

}

.polygonicon {
  right: 30px;
  top: 18px;
}

.left-side-content>div>p>img {
  top: 4px;
  /* top: -25px; */
  left: -105px;
}

.wrapper {
  background-color: #fff !important;
}

.condi-container {
  background: linear-gradient(180deg, #297ABD 0.42%, #003867 100%);
}

.selectPosition {
  width: 100%;
  height: 50px;
  border: 1px solid #297ABD;
  border-radius: 30px;
  padding-left: 2rem;
}

.zipCode {
  width: 100% !important;
  height: 45px;
  border: 1px solid #297ABD;
  border-radius: 30px;
  padding-left: 2rem;
}

.condi-container span {
  color: #fff;
}

.candi-button>button {
  background: transparent;
  color: #fff;
  border: 1px solid;
  border-radius: 35px;
  padding: 0.6rem 1.4rem;
  font-size: 1rem;
  cursor: pointer;
}

.remote-text>span {
  display: inline-block;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  margin-right: 1rem;
}

/* _______________________ */
.dashboard-head {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.dashboard-container {
  padding: 1rem 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./images/candit.svg");
}

.white-color {
  color: #fff;
  font-size: 0.8rem;
  margin-right: 1rem;
}

.select-Position {
  position: relative;
  overflow-y: auto;
  height: 45px;
  border: 1px solid #297ABD;
  box-sizing: border-box;
  border-radius: 30px;
}

.select-Position::-webkit-scrollbar-track {
  background-color: transparent;
}

.select-Position::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

.select-Position::-webkit-scrollbar-thumb {
  background-color: transparent;

}

.zip-Code {
  position: relative;
  width: 155px;
  height: 45px;
  left: 0;
  border: 1px solid #297ABD;
  box-sizing: border-box;
  border-radius: 30px;
}

.checkb0x-span>input {
  margin: 0px;
  height: 15px;
  width: 15px;
}

.checkb0x-span>span {
  font-size: 0.8rem;
  margin-right: 0.5rem;
  margin-left: 0.6rem;
  /* white-space: nowrap; */
}

.nowWrap {
  white-space: nowrap;
}

.dashboard-button>button {
  background: transparent;
  color: #fff;
  border: 1px solid;
  border-radius: 35px;
  padding: 0.5rem 0.9rem;
  /* margin-top: 1.5rem; */
  margin-top: 0.4rem;
  margin-right: 0.6rem;
  font-size: 0.8rem;
  cursor: pointer;
}

.dashboard-button>button:nth-child(3) {
  padding: 0.7rem 0.7rem;
}

.search-btn {
  width: 95px;
  height: 35px;
  background: #F79433;
  border-radius: 30px;
  text-align: center;
  border: 1px solid #F79433;
  cursor: pointer;
}

.search-btn>a {
  font-size: 13px;
  line-height: 150%;
  color: #FFFFFF;
  text-decoration: none;
}

.sidebar>div>h4 {
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
}

.card-container {
  background: #E5E5E5;
}

.card_wrapper .MuiCard-root {
  height: 100%;
  border: 2px solid transparent;
}

.card_wrapper .MuiCard-root.card_container {
  border: 2px solid #F79433;
  box-sizing: border-box;
  box-shadow: 0px 8px 24px rgb(0 0 0 / 25%);
}

.card-container>p {
  font-size: 1.1rem;
  font-family: Roboto;
  line-height: 150%;
  color: #5F5F5F;
}

.card-container>p>span {
  font-weight: 600;
}

/* ___________________________ */
.height-69 {
  height: 69vh;
}

.main-container {
  background: linear-gradient(180deg, #297ABD 0.42%, #003867 100%);
}

.info-container {
  padding: 2rem 0rem 0rem 4rem;
}

.thank-you-container {
  padding: 2rem 0rem 0rem 4rem;
}

.submitform-container {
  background: #E5E5E5;
  top: 0.2rem;
  background-repeat: no-repeat;
}

.info-container>span {
  cursor: pointer;
  color: #5F5F5F;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

}

.text-form {
  margin-right: 1.5rem !important;
  border: 1px solid;
  border-radius: 27px;
  padding: 0.4rem 0px;
  background: #fff;
  border: none;

}

#filled-search {
  padding-left: 1.5rem;
}

.text-form>label {
  color: #a9a1a1 !important;
  border: none;
  font-size: 0.9rem;
}

.text-form>div>input {
  padding-left: 0.8rem !important;
  font-weight: 500;
  padding-top: 1.5rem;
  border-radius: inherit;
  padding-bottom: 6px;
}

.zip>div>input {
  padding: 0;
  padding-top: 1.5rem;
  font-size: 0.8rem;

}

.text-form>div {
  background: #fff !important;
  border-radius: 27px !important;
}

.text-form>div::before,
.text-form>div::after {
  content: "";
  position: relative;
}

.textarea-form {
  border-radius: 10px !important;
  height: 70px;
}

.heading-bold {
  font-size: 1.5rem !important;
  /* font-weight: 700 !important; */
  /* margin-top: 1.5rem !important; */
  color: #5F5F5F !important;
}

.subHead {
  font-weight: 400 !important;
  font-size: 1.1rem !important;
  color: #5F5F5F !important;

}

.form-data-content {
  margin-top: 1.3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: end; */
  text-align: left;
}

.mr-0 {
  margin-right: 0px !important;
}

/* ______________________ */
.menu-filter-section {
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.menu-filter-section>span {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  opacity: 0.5;
  font-weight: 500;
}

.subTitle {
  text-align: left;
  font-size: 1rem !important;
  font-weight: 600 !important;
  color: #297ABD;
}

.detail-content {
  display: flex;
}

.detail-content>span {
  background: #E9E9E9;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.7rem;
  padding: 0.1rem;
  font-weight: 500;
  margin-right: 1rem;
  border-radius: 5px;

  /* width: 100px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.detail-content>p>span:nth-child(1) {
  display: block;
  width: 100%;
  color: #5F5F5F;
  opacity: 0.7;
}

.detail-content>p>span:nth-child(2) {
  display: block;
  font-weight: bold;
  font-size: 10.5px;
  color: #5F5F5F;
}

.skillstext {
  width: 100%;
  font-size: 0.8rem !important;
  text-align: left;
  margin-bottom: 12px !important;
  color: #5F5F5F !important;

  opacity: 0.7;
}

.list-filtered-data>span {
  padding-bottom: 0px;
}

.list-filtered-data>span:nth-child(2) {
  font-size: 13px;
  color: #7B7575;
  padding-top: 10px;
}

.zipcode-span {
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  position: relative;
  top: -8px;
}

.image-container {
  height: 82vh;
}

.image-container>img {
  width: 100%;
  height: 100%;
}

.graphic-img {
  right: 0;
  cursor: pointer;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #F79433 !important;
}

.next-btn {
  position: fixed;
  right: 0;
  top: 20rem;
  z-index: 9;
  cursor: pointer;

}

/* ___________________ */
input:checked~.checkmark {
  background-color: #F79433;
}

.MuiRadio-colorPrimary.Mui-checked {
  color: #F79433 !important;
}

.checkbox-input {
  cursor: pointer;
  height: 25px;
  width: 25px;
  z-index: 9;
  border-radius: 5px;
}

.poly-gonicon {
  left: 18rem;
  top: 16px;
  z-index: 99;
}

.loadMore {
  background: rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  border-radius: 6px;
  color: #808080;
  font-size: 14px;
}

.shadow-none {
  box-shadow: none !important;
}

.opacity1 {
  opacity: 0.5;
}

.opacity2 {
  opacity: 1
}

.countCandidate {
  top: 1.4rem;
  left: 4.5rem;
  z-index: 99;
  color: #fff;
}

.skillset label {
  color: #a9a1a1 !important;
  font-size: 1rem;
}

.skillset input {
  font-size: 0.8rem;
  font-weight: 500;
  color: #000;
  padding-top: 6px !important;
}

.skillset span {
  padding-left: 0px;
  font-size: 0.8rem;
  font-weight: 500;
  color: #000;
}

.skillset button>span {
  padding: 0px !important;
}

.crossPosition>div>div>div {
  /* margin-right: 3rem; */
}

.skillset div {
  border: none;
  background-color: transparent !important;
}

.skillset div:hover {
  background-color: transparent !important;
}

.MuiFilledInput-underline:before {
  content: none !important;
}

.MuiFilledInput-underline:after {
  border: 0px !important;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}



/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.skillSetOption>div>div {
  padding-top: 0.8rem !important;
}

.skillSetOption input {
  padding: 0px !important;
  font-size: 0.8rem;
  margin-top: 4px;
}

.skillSetOption>div>div>span {
  padding-left: 0px !important;
}

.skillSetOption div>svg {
  width: 20px !important;
  height: 15px !important;
}

.height67 {
  height: 65vh;
}

/* ______________SCROLL BAR CSS START__________________ */

::-webkit-scrollbar,
.tabPanelFile::-webkit-scrollbar,
.messages::-webkit-scrollbar {
  width: 10px;
  height: 64.65px;
}

/* Track */
::-webkit-scrollbar-track,
.tabPanelFile::-webkit-scrollbar-track,
.messages::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb,
.tabPanelFile::-webkit-scrollbar-thumb,
.messages::-webkit-scrollbar-thumb {
  background: #F2994A;
  border-radius: 10px;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #fff !important;
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
  background-color: #f79433 !important;
}

.PrivateValueLabel-circle-6 {
  background-color: #979696 !important;
}

/* _______________________ range slider css start ______________ */

.rangeslider-horizontal {
  height: 12px;
  border-radius: 10px;
}

.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
  box-shadow: inset 0 1px 3px rgb(0 0 0 / 40%);
}

.rangeslider {
  /* margin: 20px 0; */
  position: relative;
  top: 20px;
  background: #e6e6e6;
  -ms-touch-action: none;
  touch-action: none;
}

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  border-radius: 10px;
  top: 0;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  text-align: center;
}

.rangeslider-horizontal .rangeslider__handle {
  top: 50%;
  transform: translate3d(-50%, -50%, 0);

}

.transitionScale .MuiInputLabel-filled {
  transform: translate(12px, 15px) scale(1) !important;
}

.transitionScale .MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(12px, 10px) scale(0.75) !important;
}

.rangeslider .rangeslider__handle {
  background: #979696;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgb(0 0 0 / 40%), 0 -1px 3px rgb(0 0 0 / 40%);
}

.rangeslider-horizontal .rangeslider__handle-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: #fff;
  font-size: 13px;
}

.rangeslider-horizontal .rangeslider__handle:after {
  position: static;
}

.MuiAutocomplete-popper,
.mystyle {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.MuiAutocomplete-popper>div {
  border-radius: 10px;
}

.paddingTopRole>div>div {
  padding-top: 15px !important;
}


.letterspacing {
  letter-spacing: -0.7px;
}

.investors {
  display: none;
  position: absolute;
  background-color: #f0f0f0;
  padding-left: 3px;
  padding-right: 10px;
  padding-top: 1px;
  padding-bottom: 1px;


}

.staffing {
  display: none;
  width: 185px;
  position: absolute;
  background-color: #f9f9f9;
}

.staffing.a {
  padding-left: 3px;
  padding-right: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
 
}
.hoverMarginLeft{
  transition: margin .3s, color 0s;
  -webkit-transition: margin .3s, color 0s;
}
.headerHover:hover .hoverMarginLeft{
  margin-left: 5px;
  color: #297abd;
}
.company {
  display: none;
  width: 185px;
  position: absolute;
  background-color: #f0f0f0;
  padding-left: 3px;
  padding-right: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
}

.plus {
  display: none;
  position: absolute;
  background-color: #f0f0f0;
  padding-left: 3px;
  padding-right: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
  width: 250px;
}

.inv:hover .investors {
  display: block;
}

.sign:hover .plus {
  display: block;
}

.comp:hover .company {
  display: block;
}

.staff:hover .staffing {
  display: block;
}

.right30 {
  right: -30px;
}

.top52 {
  top: 42px;
  right: -58px;
}

.borderTop {
  border-top: 3px solid #297ABD;
}

/* -----------------------------HOVER CSS ON PLUS ICON --------------------- */
.hoverPlusMinus span {
  position: absolute;
  transition: 300ms;
  background: #297ABD;
  border-radius: 2px;
}

/* Create the "+" shape by positioning the spans absolutely */
.hoverPlusMinus span:first-child {
  top: 33%;
  bottom: 32%;
  width: 30%;
  left: 45%;
}

.hoverPlusMinus span:last-child {
  left: -10%;
  right: -29%;
  height: 9%;
  top: 45%;
}

/* Morph the shape when the button is hovered over */
.hoverPlusMinus:hover span {
  transform: rotate(90deg);
}

.hoverPlusMinus:hover span:last-child {
  left: 50%;
  right: 50%;
}