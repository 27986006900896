.top25 {
    top: -32px;
}

/* ---------------------------- DRAWER CSS START ----------------- */
#rnmm.mm-menu {
    background: #f3f3f3;
}

.mm-menu.mm-current {
    display: block;
    visibility: visible;
}
.mm-menu.mm-offcanvas {
    visibility: hidden;
    position: fixed;
}
.mm-menu {
    width: 80%;
    min-width: 140px;
    max-width: 400px;
}
.mm-menu {
    background: #f3f3f3;
    color: rgba(0,0,0,.7);
}
.mm-menu {
    background: inherit;
    display: block;
    overflow: hidden;
    padding: 0;
}
.mm-menu, .mm-menu>.mm-panel {
    margin: 0;
    position: absolute;
    left: 0;
    right: 19px;
    top: 0;
    bottom: 0;
    z-index: 0;
}

.mm-menu.mm-current .mm-panel {
    overflow-y: auto;
}

.mm-menu.mm-offcanvas .mm-panel {
    overflow: hidden;
}
.mm-menu>.mm-panel {
    background: inherit;
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
}
.mm-panel.mm-opened {
    -webkit-transform: translate3d(0%,0,0);
    -moz-transform: translate3d(0%,0,0);
    -ms-transform: translate3d(0%,0,0);
    -o-transform: translate3d(0%,0,0);
    transform: translate3d(0%,0,0);
}
.mm-menu, .mm-menu>.mm-panel {
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
}
.mm-panel {
    -webkit-transition: -webkit-transform 0.4s ease;
    -moz-transition: -moz-transform 0.4s ease;
    -ms-transition: -ms-transform 0.4s ease;
    -o-transition: -o-transform 0.4s ease;
    transition: transform 0.4s ease;
    -webkit-transform: translate3d(100%,0,0);
    -moz-transform: translate3d(100%,0,0);
    -ms-transform: translate3d(100%,0,0);
    -o-transform: translate3d(100%,0,0);
    transform: translate3d(100%,0,0);
}
.mm-menu>.mm-panel:before, .mm-menu>.mm-panel:after {
    content: '';
    display: block;
    height: 10px;
}
.mm-panel.mm-hasnavbar .mm-navbar {
    display: block;
    position: relative;
    padding-bottom: 10px;
}

.mm-menu .mm-navbar {
    border-color: rgba(255,255,255,.1);
}
.mm-panel .mm-navbar {
    display: none;
}
.mm-navbar {
    border-bottom: 1px solid transparent;
    text-align: center;
    line-height: 40px;
    padding: 0 30px;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.mm-menu>.mm-panel>.mm-listview.mm-last {
    padding-bottom: 20px;
}

.mm-listview {
    font: inherit;
    font-size: 14px;
}
.mm-listview>li {
    position: relative;
}
.mm-listview, .mm-listview>li {
    list-style: none;
    display: block;
    padding: 0;
    margin: 0;
}
#rnmm .mm-panel a, #rnmm .mm-panel a:not(.escapea):not(.button), #rnmm.mm-menu .mm-navbar-top {
    color: #787878;
}

#rnmm .mm-listview li a {
    font-size: 14px;
    font-weight: inherit;
}
.mm-listview>li>a, .mm-listview>li>span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: inherit;
    line-height: 20px;
    display: block;
    padding: 0px 10px 0px 20px;
    margin: 0;
}
.mm-menu .mm-listview>li .mm-prev:after, .mm-menu .mm-listview>li .mm-next:before {
    border-color: rgba(255,255,255,.1);
}

.mm-listview .mm-next:before {
    content: '';
    border-left-width: 1px;
    border-left-style: solid;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}
#rnmm.mm-menu .mm-navbar .mm-prev:before, #rnmm.mm-menu .mm-navbar .mm-next:after, .mm-menu .mm-listview > li .mm-prev:before, .mm-menu .mm-listview > li .mm-next:after, .mm-menu .mm-listview > li .mm-arrow:after, #rnmm.mm-menu .mm-listview > li > a.mm-subopen:after, #rnmm.mm-menu .mm-listview > li > a.mm-subclose:before {
    border-color: #c5cdd1;
}
.mm-menu .mm-listview>li .mm-prev:before, .mm-menu .mm-listview>li .mm-next:after, .mm-menu .mm-listview>li .mm-arrow:after {
    border-color: rgba(0,0,0,.3);
}
.mm-next:after, .mm-arrow:after {
    border-top: none !important;
    border-left: none !important;
    right: 20px;
}
.mm-prev:before, .mm-next:after, .mm-arrow:after {
    content: '';
    border: 2px solid transparent;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.mm-listview .mm-next {
    background: rgba(3,2,1,0);
    width: 50px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
}
a:not(.escapea):not(.button) {
    color: #787878;
}
.mm-listview a, .mm-listview a:hover {
    text-decoration: none;
}
.mm-navbar .mm-btn:first-child {
    padding-left: 20px;
    left: 0;
}
.mm-navbar .mm-btn {
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    z-index: 1;
}
.mm-prev:before {
    border-right: none !important;
    border-bottom: none !important;
    left: 20px;
}
.mm-panel.mm-subopened {
    -webkit-transform: translate3d(-30%,0,0);
    -moz-transform: translate3d(-30%,0,0);
    -ms-transform: translate3d(-30%,0,0);
    -o-transform: translate3d(-30%,0,0);
    transform: translate3d(-30%,0,0);
}
@media only screen and (min-width : 1824px) {

    .height67 {
        height: 75vh;
    }

    .left-side-content {
        padding-left: 10rem;
        display: inline-block;
        margin: auto;
    }

    .left-side-content>div>p>img {
        left: -227px;
    }

    .condi-container {
        height: 89.6vh;
    }

    .nav-main-wrapper {
        width: 70%;
    }

    .zip-Code {
        width: 190px;
    }

    .sidebar {
        width: 15%;
    }


    .submitform-container {
        height: 89vh;
    }

    .graphic-img {
        position: absolute;
        float: left;
        right: 0;
        cursor: pointer;
        bottom: -190px;
    }
}

/* .content-wrapper */
/* @media only screen and (min-width : 1224px) */
/* Extra small devices (phones, 600px and down) */


@media only screen and (max-width: 768px) {
    .resFloat{
        float: left;
    }
    .top25 {
        top: 0;
    }
    .fontSize25 {
        font-size: 1.8rem;
    }
    .content-wrapper {
        margin-top: 10rem;
    }

    .dashboard-container {
        background: linear-gradient(90deg, #297ABD 31.21%, #003867 113.09%);
    }

    .menu-list {
        position: initial !important;
        top: -27rem;
        z-index: 9999;
        right: 0;
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    }

    .backdrop {
        top: -40rem;
        left: 0;
        z-index: 99;
        opacity: 0.1;
        height: 180vh;
    }
}

@media (max-width: 425px) {
    .dashboard-container{
        z-index: 999;
        position: absolute;
        transition: .5s;
        -webkit-transition:.5s;
    }
}

@media only screen and (max-width: 478px) {
    /* .dashboard-container {
        display: none;
    } */

    .top25 {
        top: 0;
    }

    .menu-list {
        top: -30rem;
        z-index: 9999;
        right: 0;
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
        position: initial !important;
    }
}

@media only screen and (max-width: 265px) {
    .menu-list {
        top: -33rem;
        z-index: 9999;
        right: 0;
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
        position: initial !important;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px)  */
/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px)  */
/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px)  */
/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) */