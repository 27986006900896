body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiAutocomplete-listbox{
  overflow-x: hidden !important;
}
.height-15vh{
  height: 15vh;
}
.search-skill>div >div{
  background: #fff;
  border: 1px solid #7B7575;
  border-radius: 50px;
  padding-top: 0px !important;
}
.search-skill>div >div:hover{
  background-color: transparent;
}
.search-skill>div >label{
  top: -7px !important;
}
.search-skill input{
  margin-top: 0.5rem !important;
}
.top10{
  top: 10px;
} 
.right20{
  right: 20px;
}